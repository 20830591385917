<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div class="public-areas">
        <div class="weui-cells">
          <div class="weui-cell">
            <div class="weui-cell__bd">
              <div class="row shopping-rebate">
                <div class="col-xs-12 shopping-rebate-block-title">
                  <i class="icon icon-public-survey3"></i>问卷调查
                </div>
              </div>
              <div class="row shopping-rebate">
                <div class="col-xs-12 shopping-rebate-desc">
                  <p class="title">一、基本规则</p>
                  <p>
                    用户在本平台参加问卷调查，提供自己的见知信息，供问卷发布者进行数据分析，为问卷发布者提供数据参考。用户做完问卷后，问卷发布者根据问卷作答质量给予一定的金额奖励，奖励以金币形式发放到您的调研说账号中
                  </p>
                  <p class="title">二、基本概念</p>
                  <p>用户作答问卷会有各种不同结果，不同结果有不同的奖励值</p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1）<label>完成</label>：正常完成问卷的所有问题，将获得当前调查的最高奖励
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2）<label>甄别</label>：问卷调查一般是针对指定的人群进行调查，因此作答过程中会有一些条件筛选问题，如果您回答的答案不符合本次调查的目标人群，则将会被甄别（即筛选）掉。
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如：某个问卷调查目标人群18~35岁，而您在问卷中回答的岁数是40岁，则不符合调查条件，将会被甄别
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2）<label>配额满</label>：问卷调查一般是针对指定的人群进行调查，而且不同的人群有不同的数量限制，因此作答过程中如果您回答的个人信息所在的目标人群已经收集到足够多的数量，则将会返回给您配额满的结果。
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如：某个问卷调查目标人群是18~35岁（即一个配额组），要收集1000份调查，您在问卷中回答的岁数是30岁（符合条件），但是您进入问卷的时候当前配额组已经有1000人完成了该问卷，则不不再需要您继续参与，该结果即为配额满
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4）<label>IP重复</label>：您所在的当前网络下同一个IP地址已经有人参与过当前问卷。
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5）<label>设备重复</label>：您当前所用的设备（电脑、手机、pad）已经参与过当前问卷。
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6）<label>作答过快</label>：作答不认真只求速度不顾质量，比如一个问卷正常完成时间在10~15分钟，而用户用5分钟就做答完了，说明作答不认真没有认真读题，此时作答结果即为作答过快，不合格。
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7）<label>IP前后不一致</label>：作答问卷进入时的IP与作答完成时的IP不一样。
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8）<label>非作答目标</label>：为了保证问卷的正常完成率，有时会要求按指定目标作答，如果作答结果与指定目标不符则为该结果
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9）<label>未上传开放题截图</label>：为了保证开放题的出现雷同、乱答等现象，有些问卷要求答题后上传一些题型的截图，作为作答初审凭证
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10）<label>其他结果</label>：其他所有不符合条件的情况。
                  </p>
                  <p class="title">三、奖励说明</p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1）<label>成功</label>：每个问卷成功完成所有问题，将获得当前调查的最高奖励
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2）<label>甄别、配额满</label>：根据问卷实际情况酌情给予一定安慰补偿奖励
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3）<label>IP重复、设备重复、等其他结果</label>：一般不会给予奖励，特殊情况根据问卷特殊处理
                  </p>
                  <p class="">如有疑问，请随时联系客服反馈。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>

<script>
import tabbar from '../components/tabbar/index.vue'
export default {
  name: "rule",
  components: {
    tabbar
  },
};
</script>

<style></style>
